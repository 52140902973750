@import url('https://fonts.googleapis.com/css?family=Orbitron&display=swap');

body {
    top:0;
    left:0;
    width:100%;
    height:100%;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root{
    top:0;
    left:0;
    width:100%;
    height:100%;
}


