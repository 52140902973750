.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.btn, .btn:after, .btn:before { -webkit-box-sizing: border-box; -moz-box-sizing: border-box; box-sizing: border-box; }

.btn {
  border: none;
  font-family: 'Orbitron', sans-serif;
  /*font-size: inherit;*/
  color: inherit;
  background: none;
  cursor: pointer;
  padding: 25px 80px;
  display: inline-block;
  margin: 15px 30px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 700;
  outline: none;
  position: relative;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
  position: absolute;
  top: 50%;
  left: 20%;
}

.btn:after {
  content: '';
  position: absolute;
  z-index: -1;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
}


.btn-2 {
  border-radius: 3px;
}

.btn-2:hover {
  box-shadow: 0 8px #F14300;
  top: -2px;
}

.btn-2:active {
  box-shadow: 0 0 #F14300;
  top: 6px;
}

.btn-3 {
  background: #F9744B;
  color: #fff;
  box-shadow: 0 6px #F14300;
  -webkit-transition: none;
  -moz-transition: none;
  transition: none;
}


.bg{
  /*top:0;*/
  left: 0;
  z-index: -50;
  height: 100%;
  width: 100%;
  position: absolute;
}
.canvas{
  height: 100%;
  width: 100%;
}
.main {
  z-index:0;
  position: relative;
  margin:0;
}

.LottieGashapon{
  top:0;
  z-index: -10;
  position: absolute;
}

.AwesomeButton{
  top: 50%;
}

.title{
  font-family: 'Orbitron', sans-serif;
  font-size: 12pt;
  text-transform: uppercase;
  justify-content: center;
  /*margin-top: 100px;*/
  padding-top: 100px;
  text-shadow: 1px 1px #F5F5F5,
  2px 2px #F5F5F5,
  3px 3px #F5F5F5,
  4px 4px #F5F5F5,
  5px 5px #F5F5F5,
  6px 6px #F5F5F5,
  7px 7px #F5F5F5,
  8px 8px #F5F5F5,
  9px 9px #F5F5F5,
  10px 10px #F5F5F5,
  11px 11px #F5F5F5,
  12px 12px #F5F5F5,
  13px 13px #F5F5F5,
  14px 14px #F5F5F5,
  15px 15px #F5F5F5,
  16px 16px #F5F5F5;
}
.text {
  fill: none;
  stroke-width: 0.75px;
  stroke-linejoin: round;
  stroke-dasharray: 35px;
  stroke-dashoffset: 0;
  -webkit-animation: stroke 8s infinite linear;
  animation: stroke 6s infinite linear;
  margin: 0;

}

.title:hover{
  text-shadow: 1px 1px #F5F5F5,
  2px 2px #F5F5F5,
  3px 3px #F5F5F5,
  4px 4px #F5F5F5,
  5px 5px #F5F5F5,
  6px 6px #F5F5F5,
  7px 7px #F5F5F5,
  8px 8px #F5F5F5,
  9px 9px #F5F5F5,
  10px 10px #F5F5F5,
  11px 11px #F5F5F5,
  12px 12px #F5F5F5,
  13px 13px #F5F5F5,
  14px 14px #F5F5F5,
  15px 15px #F5F5F5,
  16px 16px #F5F5F5,
  17px 17px #F5F5F5,
  18px 18px #F5F5F5,
  19px 19px #F5F5F5,
  20px 20px #F5F5F5,
  22px 22px #F5F5F5,
  23px 23px #F5F5F5,
  24px 24px #F5F5F5,
  25px 25px #F5F5F5;}
/*#F14300*/
.text:nth-child(5n + 1) {
  stroke: #F9744B;
  -webkit-animation-delay: -1.2s;
  animation-delay: -1.2s;
}
.text:nth-child(5n + 2) {
  stroke: #F7D700;
  -webkit-animation-delay: -2.4s;
  animation-delay: -2.4s;
}

.text:nth-child(5n + 3) {
  stroke: #F14300;
  -webkit-animation-delay: -3.6s;
  animation-delay: -3.6s;
}

.text:nth-child(5n + 4) {
  stroke: #F78700;
  -webkit-animation-delay: -4.8s;
  animation-delay: -4.8s;
}

.text:nth-child(5n + 5) {
  stroke: #6565EA;
  -webkit-animation-delay: -6s;
  animation-delay: -6s;
}

@-webkit-keyframes stroke {
  100% {
    stroke-dashoffset: -400;
  }
}

@keyframes stroke {
  100% {
    stroke-dashoffset: -400;
  }
}
